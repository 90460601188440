import styled from 'styled-components';
import {BLACK,  DARK_GRAY,  dFlexCenter,  GRAY92,  LIGHT_GRAY2, LIGHT_GRAY4, SHADOW_COLOR, VERY_PALE_BLUE} from '../../../../globalVariables/GlobalVariables';
import realtimeFonts from '../../fonts';
import {getColor} from '../../eventRealtime/EventRealtime.css';
import {circle, circleWrap, percent} from '../../../tickets/components/ticketRow/ticketProgress/TicketProgress.css';

export const section = styled.div`
  flex: 1;
  padding-inline: 20px;
  border-left: 1px solid ${LIGHT_GRAY2};
  &:last-child {
    border-left: none;
  }
`;
export const title = styled.div`
  ${realtimeFonts.windowTitle}
  color: ${p=>getColor(p.theme.isDarkMode)};
  margin-bottom: 5px;
`;

export const text = styled.div`
  width: max-content;
  padding: 2px 14px;
  font-weight: bold;
  background: ${GRAY92};
  color: ${BLACK};
  border-radius: 11px;
  text-align: center;
`;

export const amount = styled.div`
  ${realtimeFonts.value};
  margin-bottom: 10px;
`;
export const insightsItem = styled.div`
  &, & span {
    min-width: fit-content;
  }
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  ${realtimeFonts.text}
`;
export const insightsNumber = styled.div`
  ${realtimeFonts.textBold}
  padding: 2px 14px;
  background-color: ${LIGHT_GRAY4};
  border-radius: 11px;
  font-weight: bold;
  background-color: ${GRAY92};
  width: 50px;
  height: 21px;
  color: ${p=>p.theme.isDarkMode?DARK_GRAY:BLACK};
  ${dFlexCenter}
`;

export const insights = styled.div`
  padding-bottom: 5px;
  height: 50%;
  justify-content: space-between;
  border-radius: 8px;
  display: flex;
  align-items: flex-end;
`;

export const progressWrapper = styled.div`
  height: 100px;
  ${circleWrap}{
    width: 90px ;
    height: 90px;
    box-shadow: 0 3px 6px ${SHADOW_COLOR};
    position: unset;
  }
  ${circle} {
    width: 75%;
    height: 75%;
    ${realtimeFonts.amount}
    background-color: ${VERY_PALE_BLUE};
 }
  ${percent} {
    ${realtimeFonts.text}
    display: contents;
    height: 26px;
  }
`;

export const goalText = styled.span`
  ${realtimeFonts.smallText}
  line-height: 13px;
`;