import React from 'react';
import {GOOGLE_ANALYTICS_ID} from '../../config/config';
import {Helmet} from 'react-helmet';
import {useLocation} from 'react-router-dom';

const Analytics = () => {
    if(process.env.NODE_ENV !== 'production') {
        return null;
    }
    const location = useLocation();
    
    // List of paths to exclude from analytics
    const excludedPaths = ['/login', '/signUp'];
    
    // Don't render analytics if in development or on excluded paths
    if(excludedPaths.some(path => path.toLowerCase() === location.pathname.toLowerCase()) || location.pathname === '/') {
        return null;
    }
    
    return (
        <Helmet>
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`} />
            <script>
                {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', '${GOOGLE_ANALYTICS_ID}');
                `}
            </script>
        </Helmet>
    );
};

export default Analytics;