import * as css from './HubLogoStep.css';
import LogoTopStep from '../../components/logoStep/LogoTopStep';

function HubLogoTopStep() {

    return (
        <css.hubLogoTopStep>
            <LogoTopStep/>
        </css.hubLogoTopStep>
    );
}

export default HubLogoTopStep;
