import * as css from './sideFormPopup.css';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import useShow from '../../hooks/useShow';
import Close from '../close/Close';
import InputSearch from '../inputSearch/InputSearch';
import Button from '../button/button/Button';
import List from '../list/List';
import {useHistory} from 'react-router-dom';

function Title({close, formRef, isShowOptions=true, title, popupName, saveText='', searchProps=null, menuList=null, menuClicked=null, menuTitle}) {
    const putXHR = useSelector((state: any) => state.ui.putXHR);
    const [isWait, setIsWait] = useState(false);
    const listOC = useShow();
    let timer = null;
    const history = useHistory();

    useEffect(() => {
        clearTimeout(timer);
        setIsWait(putXHR);
        timer = setTimeout(() => {
            setIsWait(null);
        }, 3000);
    }, [putXHR]);
    useEffect(() => () => clearTimeout(timer), []);

    const handleSubmit = () => {
        if(formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    const btnProps = {
        text: saveText,
        width: 60,
        onClick: handleSubmit,
        class: isWait,
    };

    return (<>
        <css.Header  >
            <css.Right>
                <css.Close ><Close onClick={close} className={''}/></css.Close>
                <css.TitleAccount>
                    <css.Title>{title} &#8226;</css.Title>
                    <css.popupName>{popupName}</css.popupName>
                </css.TitleAccount>
            </css.Right>
            {isShowOptions&& <css.Left>
                {searchProps && <css.search><InputSearch {...searchProps} /></css.search>}
                {saveText && <Button BtnProps={btnProps} />}
                {menuList?.length && <css.menuDiv onClick={listOC.isOpen ? listOC.close : listOC.open} >
                    {listOC.isOpen && <List close={listOC.close} title={menuTitle} showClass={listOC.showClass}
                        list={menuList} top="40" left="10px" onClick={(item) => menuClicked && menuClicked(item, history, listOC.close)}/>}
                </css.menuDiv>}
            </css.Left>}
        </css.Header>

    </>
    );
}

export default Title;
