function approximateFraction(numerator, denominator, precision = 1000) {
    const decimalValue = numerator / denominator;
    let closestNumerator = Math.round(decimalValue * precision);
    let closestDenominator = precision;
    const findGreatestCommonDivisor  = (a, b) => (b ? findGreatestCommonDivisor (b, a % b) : a);
    const divisor = findGreatestCommonDivisor (closestNumerator, closestDenominator);

    closestNumerator /= divisor;
    closestDenominator /= divisor;

    return [closestNumerator, closestDenominator];
}
export default approximateFraction;