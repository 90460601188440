import {useTranslation} from 'react-i18next';
import Accordion from '../../../../package/componnent/accordion/Accordion';
import {Form, Formik} from 'formik';
import MarketingPromotionAds from './marketingPromotionAds/marketingPromotionAds';
import MarketingPromotionDefinitions from './marketingPromotionDefinitions/marketingPromotionDefinitions';

function MarketingPromotionSettings({formRef}) {

    const {t} = useTranslation('promotionMessage');

    const initialValues={};
    const validationSchema={};
    const onSubmitForm=(values)=>{
        console.log(values);
    };
    return (
        <Formik innerRef={formRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={values => { onSubmitForm(values); }}
        >
            <Form >
                <Accordion title={t('settings')} isAlwaysOpen={true}  dataCyTitle={undefined} forwardRef={undefined} >
                    <MarketingPromotionDefinitions/>
                </Accordion>
                <Accordion title={t('ads')} isAlwaysOpen={true} dataCyTitle={undefined} forwardRef={undefined}>
                    <MarketingPromotionAds/>
                </Accordion>
            </Form>
        </Formik>
    );
}

export default MarketingPromotionSettings;