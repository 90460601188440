import {useTranslation} from 'react-i18next';
import Btn from '../../../../component/button/btn/Btn';
import MobilePopup from '../../../../component/newPopup/mobilePopup/MobilePopup';
import * as css from './EditTextPopup.css';
import {useState} from 'react';

function EditTextPopup({show, value, onSave, title = '', children = null}) {
    const {showClass, close} = show;
    const {t} = useTranslation('layout');
    const [text, setText] = useState(value);
    const handleInput = (event) => {
        const textarea = event.target;
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
        setText(event.target.value);
    };
    return (
        <css.wrapPopup>
            <MobilePopup showClass={showClass} close={close} title={title} list={undefined} forwardRef={undefined} dataCyName={undefined} >
                <css.wrapAll>
                    {children}
                    <textarea value={text} onChange={handleInput}/>
                    <css.buttons>
                        <Btn className={'cancel'} onClick={close} disabled={undefined}>{t('close')}</Btn>
                        <Btn onClick={()=>{onSave(text);}} className={undefined} disabled={undefined}>{t('save')}</Btn>
                    </css.buttons>
                </css.wrapAll>
            </MobilePopup>
        </css.wrapPopup>
    );
}

export default EditTextPopup;