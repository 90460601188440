import {useTranslation} from 'react-i18next';
import FileUploader from '../dropzone/Dropzone';
import * as css from './FileUpload.css';

export function downloadURI(url, filename) {
    fetch(url)
        .then(response => response.blob())
        .then(blob => {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            link.click();
        })
        .catch(console.error);
}
export function downloadImage(image, filename) {
    const link = document.createElement('a');
    link.href = image;
    link.download = filename;
    link.click();
}

function FileUpload({title, desc, remove, value = null, children, className}) {
    const {t} = useTranslation('package');

    return (
        <css.fileContainer className={className}>
            <css.fileUInput>
                {children && <css.fiIcon>{children}</css.fiIcon>}
                <css.fileUpload desc={desc}>
                    <css.attach />
                    <css.upload>
                        <FileUploader maxFiles={1} btnTitle={title} type="image/jpeg, image/jpg, application/pdf" />
                    </css.upload>
                    {(value && value != '') &&
                        <css.download onClick={() => downloadURI(value, title)}>{t('download')}</css.download>
                    }
                    <css.garbage onClick={remove} />
                </css.fileUpload>
            </css.fileUInput>
        </css.fileContainer>
    );
}
export default FileUpload;