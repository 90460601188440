import * as css from './BoxofficeIframe.css';
import {useSelector} from 'react-redux';
import {REACT_APP_ENV, TICK_DOMAIN_MY, FRONT_DOMAIN} from '../../config/config';
import readCookie from '../../library/readCookie';
// import {useTranslation} from 'react-i18next';
// import {useEffect} from 'react';

function BoxofficeIframe() {
    const newIframe = useSelector(state => state.ui.newIframe);
    const event = useSelector(state => state.event);
    const tickchakToken = readCookie('tickchak_token');
    // const {t, i18n} = useTranslation('layout');

    // useEffect(() => {
    //     const currentLang = i18n.language; 
    //     i18n.changeLanguage('he');
    //     const title = `Tickchak | ${queryString}/boxOffice - ${t('package')} ${t(queryString)}/${t('boxOffice')}`;
    //     document.title = title;
    //     i18n.changeLanguage(currentLang);
    // }, [i18n, t]);

    const iframeSrc = {
        'ev' : event.ev,
        'hash' : event.hash,
        'lang' : event.lang,
        'origin' : TICK_DOMAIN_MY,
        'top_location' : FRONT_DOMAIN,
        'boxoffice' : 1,
        'is_iframe' : 1,
        'is_new_app' : 1,
        'tickchakNewApp' : 1,
    };
    if(REACT_APP_ENV){
        iframeSrc.tickchak_token = tickchakToken;
    }
    const queryString = new URLSearchParams(iframeSrc).toString();

    return (
        newIframe.openBoxoffice && <>
            {/* <title>{title}</title> */}
            <css.boxofficeIframe>
                <iframe id={'tickchak-form-old'} src={`${TICK_DOMAIN_MY}/embed?${queryString}`}/>
            </css.boxofficeIframe>
        </>
    );
}
export default BoxofficeIframe;