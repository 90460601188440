import {useEffect} from 'react';
import MobilePopup from '../../../../component/newPopup/mobilePopup/MobilePopup';
import QRCode from '../../../../component/QrCode/QrCode';

function QrCodePopup({url, close, open, isOpen, showClass}){
    
    useEffect(()=>{open();
        return () => close();
    },[]);

    return (isOpen? <MobilePopup
        showClass={showClass}
        close={close} 
        title={undefined}
        list={undefined}
        forwardRef={undefined}
        dataCyName={'systemMessagePop'}
        cssStyle={'height:380px; width:440px;'}
    >
        <QRCode url={url} />
    </MobilePopup> : <></>);
}
export default QrCodePopup;