import styled, {css} from 'styled-components';
import {BGImageCenter, GRAY, LIGHT_GRAY, dFlexCenter} from '../../../../globalVariables/GlobalVariables';
import {drop} from '../../../../component/dropzone/Dropzone.css';
import {uploadImage} from '../../../../assets/symbols/symbols/Symbols.css';
const cssNoImage=css`

`;
export const upload=styled.div`
    ${p=>p.image?BGImageCenter(p.image,'cover'):cssNoImage}
    margin-top: 30px;
    position: relative;
    width: 125px;
    height: 125px;
    border: 1px dashed ${GRAY};
    border-radius: 10px;
    cursor: pointer;
    &.empty{
        ${BGImageCenter(uploadImage,'50px')}
        background-color: ${LIGHT_GRAY};
    }
    section{
        height: 100%;
        }
    .dropzone{
        height: 100%;
    }
    ${drop}{
            height: 100%;
        }

`;

export const topImage=styled.div`
${p=>BGImageCenter(p.image,'cover')}
width: 70px;
height: 70px;
border-radius: 15px;

`;
export const text=styled.div`
margin-inline-end: 20px;
`;
export const top=styled.div`
${dFlexCenter}
display: flex;
align-items: center;

`;
