import {useTranslation} from 'react-i18next';
import * as css from './MarketingHeader.css';

function MarketingHeader() {
    const {t} = useTranslation('promotionMessage');

    return (
        <>
            <css.title>
                {t('ad')}
            </css.title>
            <css.title/>
            <css.title>
                {t('events')}
            </css.title>
            <css.title>
                {t('views')}
            </css.title>
            <css.title>
                {t('clicks')}
            </css.title>
            <css.title>
                {t('pause')}
            </css.title>
        </>
    );
}
export default MarketingHeader;
