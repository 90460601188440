import styled from 'styled-components';
import {DEFAULT_COLOR, dFlexCenter, mobileMedia, WHITE} from '../../../globalVariables/GlobalVariables';
import {btn} from '../../button/btn/Btn.css';
import {popUpBody} from '../../newPopup/mobilePopup/MobilePopup.css';
import {wrapper, addGroup} from '../../anotherOneHorizontal/AnotherOneHorizontal.css';
import {popUpTop} from '../../newPopup/topPopup/TopPopup.css';
import {buttons} from '../../../pages/realtime/components/editTextPopup/EditTextPopup.css';

export const container = styled.div`
    width: 100%;
    ${dFlexCenter}
    flex-direction: column;
    ${popUpTop}{
        background-color: ${WHITE};
    }
    ${popUpBody}{
        background: none;
        background-color: ${WHITE};
        height: max-content;
        width: 350px;
        textarea {
            inset-inline-start: 15px;
            inset-block-start: 15px;
            min-height: 150px;
        }
        ${buttons}{
            position: relative;
            border-top: unset;
            justify-content: center;
            inset-block-end: unset;
            margin-top: 20px;
        }
    }
    ${wrapper}{
        height: 127px;
        width: 157px;
        background-color: ${WHITE};
        ${addGroup}{
            display: block
        }
    }
`;

export const title = styled.div`
    letter-spacing: 0.7px;
    font-size: 25px;
    font-weight: 700;  
    ${mobileMedia}{
        padding-top: 20px;
    }
`;

export const description = styled.div`
    font-size: 15px;
    padding-block: 20px;
`;
export const images = styled.div`
    height: 170px;
    display: flex;
    align-items: flex-end;
    width: 100%;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
`;
export const styles = styled.div`
    ${btn}{
        background-color: #FFFFFF;
        &.active{
            background-color: ${DEFAULT_COLOR};
        }
    }
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
`;
export const titleText = styled.div`
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
`;
export const text = styled.div`
    text-align: center;
    font-size: 16px;
    padding-inline: 20px;
    white-space: pre-line;
`;