import styled from 'styled-components';
import {desktopMedia, mobileMedia, scrollbar} from '../../../../globalVariables/GlobalVariables';
import {side as packageSide} from '../packageSettings/PackageSettings.css';
import {percentage as  progressPercentage ,title as progressTitle}  from '../progress/Progress.css';

export const container=styled.div`
     ${mobileMedia}{

    }
    ${desktopMedia}{ 

    }
    padding-top: 10px;
    `;

export const progresses=styled.div`
    display: flex;
    justify-content: space-around;
    ${desktopMedia}{
        padding: 20px 20px;
    }
    ${mobileMedia}{
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-items: center;
        row-gap: 13px;
    }
`;
export const percentage=styled(progressPercentage)`
    margin-block: 15px 14px;
`;
export const title=styled(progressTitle)``;
export const inputs=styled.div`
    padding: 20px 0;

    ${desktopMedia}{
        display: flex;
        gap: 15px;
        padding-inline: 20px;
    }
`;
export const packages = styled.div`
    display: flex;
    width: max-content;
`;
export const wrapPackages = styled.div`
    ${scrollbar}
`;
export const side=styled(packageSide)`
    padding:20px 0;`;

export const mailingName=styled.div`
    font-weight: 600;
`;
export const mailingTitle=styled.div`
    font-size: 14px;
`;

