import styled from 'styled-components';
import {mobileMedia} from '../../../../../globalVariables/GlobalVariables';

export const openPromotionMessagePopup = styled.div`
    cursor: pointer;
    transition:all 400ms;
    &:hover{
        transform: scale(1.1);
    }
    ${mobileMedia}{
        position: absolute;
        inset-block-end: -10px;
        inset-inline-start: 65px;
    }
 `;