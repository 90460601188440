import SettingItem from '../../settingItem/SettingItem';
import MultipleAccountForm from '../../multipleAccountForm/MultipleAccountForm';
import {useSelector} from 'react-redux';

function HubMultipleAccount() {
    const {pid}=useSelector(s=>s.account);
    const {multipleAccounts} = useSelector(s=>s.event.settings);
    
    return (
        <>
            <SettingItem name={'managedAccounts'} settingCategory={'multipleAccounts'} disabled={multipleAccounts?.allAccounts}>
                <MultipleAccountForm />
            </SettingItem>
            {pid === 2000 && <SettingItem name={'allAccounts'} settingCategory={'multipleAccounts'}/> }
        </>
    );
}

export default HubMultipleAccount;