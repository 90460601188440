import styled from 'styled-components';
import {basicRow} from '../../../../../../component/newTable/newRow/Row.css';
import {scrollbar} from '../../../../../../globalVariables/GlobalVariables';

export const container = styled.div`
    ${scrollbar}
    ${basicRow}{
        justify-content: space-around;
        height:45px;
        padding-inline: 15px;
    }
 `;
