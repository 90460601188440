import Btn from '../../button/btn/Btn';
import ImageUpload from '../../imageUpload/ImageUpload';
import * as css from './LastScreen.css';
import {useTranslation} from 'react-i18next';
import getImageDimensions from '../../../library/GetImageDimensions';
import zIndex from '../../../globalVariables/zIndex';

function LastScreen({setCurrentScreen,setIsForMobile,image,mobileImage,isForMobile,setImage,setMobileImage}) {

    const {t} = useTranslation('newEvent');
    const onClick=(isMobile)=>{
        if(isForMobile!==isMobile)
            setIsForMobile(isMobile);
        setCurrentScreen(1);
    };
    
    const dimensions = getImageDimensions(image);
    const imageWidth = dimensions?.width < dimensions?.height ?  93 : 212;
        
    return (
        <css.container>
            <css.title >{t('designTitle')}</css.title>
            <css.description >{t('designDescription')}</css.description>
            <css.images>
                <css.right>
                    <css.mobileImage>
                        <ImageUpload  onDrop={setMobileImage}>
                            <css.wrapMobileImage image={mobileImage} />
                        </ImageUpload>
                    </css.mobileImage>
                    <Btn  onClick={()=>onClick(true)} style={`z-index:${zIndex.baseHighest};`}>{t('changeImage',{screenType:t('mobile')})}</Btn>
                    <css.size>{t('recommendedSize',{size:'620*868'})}</css.size>
                </css.right>
                <css.left>
                    <css.descImage>
                        <ImageUpload onDrop={setImage}>
                            <css.wrapDescImage imageWidth={imageWidth} image={image} />
                        </ImageUpload>
                    </css.descImage>
                    <Btn  onClick={()=>onClick(false)} style={`z-index:${zIndex.baseHighest};`}>{t('changeImage',{screenType:t('desktop')})}</Btn>
                    <css.size>{t('recommendedSize',{size:'650*1600'})}</css.size>
                </css.left>
            </css.images>
        </css.container>
    );
}

export default LastScreen;
