import {useRef} from 'react';
import OfficeOptions from '../officeOptions/OfficeOptions';
import PopUpFrame from '../popUpFrame/PopUpFrame';
import * as css from './sideFormPopup.css';
import Title from './title';

type ISideFormPopup = {
    children?: React.ReactNode;
    showClass: string;
    close?: () => void;
    page: string;
    bodyComponents: {[key: string]: JSX.Element};
    t: any;
    menuList?: any[];
    menuTitle?: string;
    menuClicked?: (item: any)=>void;
    popupName?: string;
    isShowOptions?: boolean;
    searchProps?: any;
    saveText?: string;
    formRef?: any;
 };

function SideFormPopup(props: ISideFormPopup) {
    const {saveText, formRef: propsRef, children, showClass, close, page, bodyComponents, t, menuList, menuTitle, menuClicked, popupName, isShowOptions=true, searchProps} = props;
    const formRef = propsRef || useRef();

    return (
        <PopUpFrame showClass={showClass} forwardRef={formRef} >
            <css.Popup className={showClass} >
                <Title saveText={t(saveText)} menuTitle={t(menuTitle)} menuClicked={menuClicked}
                    menuList={menuList} close={close} formRef={formRef} popupName={popupName}
                    isShowOptions={isShowOptions} title={t(page)} searchProps={searchProps}
                />
                {isShowOptions&&
            <>
                <css.Management>
                    <OfficeOptions />
                </css.Management>
            </>}
                <css.popUpbody className={isShowOptions&&'showBanner'}>
                    {children}
                    {bodyComponents[page]}
                </css.popUpbody>
            </css.Popup>
        </PopUpFrame>
        
    );

}
export default SideFormPopup;
