import React, {useState, useEffect, useRef} from 'react';
import * as css from './SupportComponent.css';
import useMobile from '../../hooks/useMobile';
import useTimeout from '../../hooks/useTimeout';

export default function SupportCloseButton({isOpen, onClick}) {
    const containerRef = useRef(document.getElementById('glassix-widget-iframe-container'));
    const [buttonStyle, setButtonStyle] = useState({top: 0, left: 0, zIndex: 0});
    const isMobile = useMobile();
    const timer = useTimeout();

    const getContainerRef = () =>{
        if(!containerRef.current) {
            containerRef.current = document.getElementById('glassix-widget-iframe-container');
        }
        if(containerRef.current.classList.contains('open'))
            return containerRef.current;
    };
    useEffect(() => {
        const getButtonStyle = () => {
            const currentContainerRef = getContainerRef();
            const containerRect = currentContainerRef?.getBoundingClientRect();
            const halfButtonWidth = css.BUTTON_WIDTH / 2;
            let top, left = 0;
            if(isMobile){
                left =  halfButtonWidth;
                top = containerRect.top + halfButtonWidth;
            } else {
                left = ((containerRect.width/2) + 30 - halfButtonWidth);
                if(isOpen) 
                    top = Math.max(containerRect.top - css.BUTTON_WIDTH - 10, 0);
                else 
                    top = -css.BUTTON_WIDTH; 
            }
            const zIndex= isOpen ? (parseInt(window.getComputedStyle(currentContainerRef)?.getPropertyValue('z-index'), 10) + 1) : 0;
            return {top, left, zIndex};
        };
        if(isOpen)
            timer.current = setTimeout(()=>{
                setButtonStyle(getButtonStyle());
            }, 500) as any;
        else 
            setButtonStyle(bStyle=>({
                ...bStyle,
                top: -css.BUTTON_WIDTH-10,
            }));
    }, [isOpen, isMobile]);

    return (
        <css.closeButton
            {...buttonStyle}
            onClick={onClick}
        />
    );
}
