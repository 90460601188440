import * as css from './Progress.css';
import {useEffect, useState} from 'react';
import {CircularProgressbarWithChildren, buildStyles} from 'react-circular-progressbar';

function Progress({partialValue=0, totalValue=0, title,children}) {
    const [isShow, setIsShow] = useState(false);
    const percentage = () =>( Math.trunc(((100 * partialValue) / (totalValue?totalValue:partialValue))||0));

    const styles = {
        ...buildStyles({rotation: 0.45, pathColor: '#76D6E8', trailColor: '#DBDBDB',pathTransitionDuration:0.7}),
        trail: {strokeWidth: 2}
    };

    useEffect(() => {
        setIsShow(true);
    }, []);

    return (
        <css.content>
            <CircularProgressbarWithChildren
                value={isShow?percentage():0}
                strokeWidth={5}
                background={false}
                counterClockwise
                styles={styles}>
                {children?children: <>
                    < css.percentage > {percentage()}<css.percentageChar>%</css.percentageChar></css.percentage>
                    <css.text>{partialValue + '/' + totalValue}</css.text>
                    <css.title>{title}</css.title>
                </>
                }
            </CircularProgressbarWithChildren>
        </css.content>
    );
}

export default Progress;
