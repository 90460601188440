import {useEffect} from 'react';
import * as css from './marketingPromotionAds.css';
import {getPromotionMessages} from '../../../../../../redux/promotion/promotionActions';
import Table from '../../../../../../component/newTable/Table';
import {useSelector} from 'react-redux';
import MarketingHeader from './marketingTable/MarketingHeader';
import MarketingRow from './marketingTable/MarketingRow';

function MarketingPromotionAds() {
    const {promotionMessages: messages, getPromotionMessagesXHR} = useSelector((s: any)=> s.promotion);

    useEffect(() => {
        if(!getPromotionMessagesXHR)
            getPromotionMessages().then();
    }, []);
    if(!messages)
        return <></>;
    return (
        <css.container>
            <Table
                header={MarketingHeader}
                list={messages?.map(message => ({comp: MarketingRow, props: {message}, key: message.pmid}))}
                checked={undefined}
                buttonsChildren={undefined}
                searchProps={undefined} />
        </css.container>
    );
}

export default MarketingPromotionAds;