import {useTranslation} from 'react-i18next';
import List from '../../../../../component/list/List';
import useShow from '../../../../../hooks/useShow';
import * as css from './marketingPromotionsMenu.css';
import {useSelector} from 'react-redux';
import Tooltip from '../../../../../component/tooltip/Tooltip';
import MasterPermission from '../../../../components/rolePermission/MasterPermission';
import {useRef, useState} from 'react';
import SideFormPopup from '../../../../../component/sideFormPopup/sideFormPopup';
import MarketingMessage from '../../../../../assets/symbols/icons/MarketingMessage';
import MarketingPromotionSettings from '../marketingPromotionSettings/marketingPromotionSettings';
import MarketingPromotionInsights from '../marketingPromotionInsights/marketingPromotionInsights';

function MarketingPromotionsMenu() {
    const marketingPromotionsShow = useShow();
    const {t} = useTranslation('realtime');
    const menuShow = useShow();
    const [page, setPage] = useState('');
    const packageName = useSelector((state: any) => state.account?.title);
    
    const menuList = [
        {
            title: t('settings'),
            text: 'settings',
        },
        {
            title: t('ads'),
            text: 'ads',
        },
        {
            title: t('insights'),
            text: 'insights',
        }
    ];

    const onListClick = (item:any)=>{
        setPage(item.text);
        marketingPromotionsShow.open();
    };

    const formRef=useRef();
    const body = {
        'settings':<MarketingPromotionSettings formRef={formRef}/>,
        'ads': <MarketingPromotionSettings formRef={formRef}/>,
        'insights': <MarketingPromotionInsights formRef={formRef}/>
    };

    return (
        <MasterPermission>
            {marketingPromotionsShow.isOpen && <SideFormPopup menuClicked={onListClick} menuList={menuList} t={t} bodyComponents={body} {...marketingPromotionsShow}  popupName={packageName} page={page} />}
            <Tooltip text={t('promotionMessage')} dataTitleIcon={undefined}>
                <css.openPromotionMessagePopup onClick={()=>menuShow.open()} data-cy="openPromotionMessagePopup" >           
                    <MarketingMessage />
                </css.openPromotionMessagePopup>
            </Tooltip>
            {menuShow.isOpen && <List onClick={onListClick} {...menuShow} close={menuShow.close} list={menuList} />}
        </MasterPermission>
    );
}

export default MarketingPromotionsMenu;