import Mailing from '../pages/mailing/Pages/Mailing/Mailing';
import EventDetails from '../pages/eventDetails/cluster/Cluster';
import Groups from '../pages/tickets/groups/Groups';
import CoursePage from '../pages/tickets/coursePage/CoursePage';
import GroupPage from '../pages/tickets/groups/groupPage/GroupPage';
import Tickets from '../pages/tickets/tickets/Tickets';
import AttendancePage from '../pages/attendance/attendancePage/AttendancePage';
import ParticipantsPage from '../pages/attendance/participantsPage/ParticipantsPage';
import Team from '../pages/team/Team';
import NewTicketsEvent from '../pages/newEvent/newEvent/NewTicketsEvent';
import PixelsPage from '../pages/pixels/PixelsPage/PixelsPage';
import LeadPage from '../pages/lead/leadPage/LeadPage';
import BrandingPage from '../pages/branding/BrandingPage';
import NewEvent from '../pages/newEvent/newEvent/newEvent';
import EventRealtime from '../pages/realtime/eventRealtime/EventRealtime';
import HubDesign from '../pages/hub/hubDesign/HubDesign';

export const menuEvent = {
    create: {
        access: 'create_event',
        path: '/create',
        title: 'createYourEvent',
        component: 'MyIframe'
    },
    realtime: {
        access: 'access',
        path: '/realtime',
        component: EventRealtime,
        events: ['ticket', 'area', 'givechak']
    },
    realtimeHub: {
        access: 'access',
        path: '/realtime',
        component: HubDesign,
        events: ['hub']
    },
    realtimeOld: {
        access: 'access',
        path: '/realtime',
        component: 'MyIframe',
        events: [ 'timetable', 'listing', 'product', 'table', 'course','frontpage']
    },
    dashboard: {
        access: 'manage event',
        path: '/dashboard',
        component: 'MyIframe'
    },
    crm: {
        access: 'access_crm',
        path: '/crm',
        title: 'crm',
        subtitleItemTitle: 'crm',
        menu: ['main', 'top'],
        component: 'MyIframe',
        events: ['ticket', 'area', 'timetable', 'givechak', 'listing', 'product', 'table', 'course']
    },

    'crm/timetable': {
        access: 'manage event',
        path: '/crm/timetable',
        title: 'lectures',
        subMenu: 'crm',
        component: 'MyIframe',
        events: ['timetable']
    },
    'crm/billings': {
        access: 'access_crm',
        path: '/crm/billings',
        title: 'billings',
        subMenu: 'crm',
        component: 'MyIframe',
        events: ['ticket', 'area', 'timetable', 'givechak', 'listing', 'product', 'table', 'course']
    },
    event: {
        access: 'manage event',
        path: '/event',
        title: 'ofDetails',
        subtitleItemTitle: 'eventDetails',
        menu: ['main', 'top'],
        component: NewEvent,
        events: ['hub','ticket', 'area', 'timetable', 'givechak', 'listing', 'product', 'table', 'frontpage']
    },
    eventCourse: {
        access: 'manage event',
        path: '/event',
        title: 'ofDetails',
        subtitleItemTitle: 'eventDetails',
        menu: [],
        component: NewTicketsEvent ,
        events: ['course']
    },
    'event/bookkeeping': {
        access: 'master',
        path: '/event/bookkeeping',
        title:'bookkeeping',
        menu: ['top'],
        subMenu: 'event',
        component: 'MyIframe',
        events: ['ticket', 'area']
    },
    eventCluster: {
        access: 'manage event',
        path: '/eventCluster',
        title: 'clusterDetails',
        subtitleItemTitle: 'eventDetails',
        menu: ['main', 'top'],
        component: EventDetails,
        events: ['cluster']
    },
    'tickets/seats': {
        access: 'manage event',
        path: '/tickets/seats',
        title: 'seats',
        subMenu: 'tickets',
        menu: ['top'],
        component: 'MyIframe',
        events: ['area'],
        permission: 'area'
    },
    'tickets/table': {
        access: 'manage event',
        path: '/tickets/seats',
        title: 'seats',
        subMenu: 'tickets',
        component: 'MyIframe',
        events: ['table']
    },
    courses: {
        access: 'manage event',
        path: '/courses',
        title: 'courses',
        menu: ['main', 'top'],
        component: CoursePage,
        events: ['course']
    },
    'courses/groups': {
        access: 'manage event',
        path: '/courses/groups',
        title: 'groups',
        subMenu: 'courses',
        menu: ['top'],
        component: Groups,
        events: ['course']
    },
    'courses/groups/:pageId': {
        access: 'manage event',
        path: '/courses/groups/:pageId',
        subMenu: 'courses/groups',
        menu: ['subTop'],
        idDesc: 'groups',
        idParams: 'pageId',
        component: GroupPage,
        events: ['course']
    },
    'courses/coupons': {
        access: 'manage event',
        path: '/courses/coupons',
        title: 'coupons',
        subMenu: 'courses',
        component: 'MyIframe',
        events: ['course']
    },
    'courses/branding': {
        access: 'design',
        path: '/courses/branding',
        title: 'branding',
        subMenu: 'courses',
        component: BrandingPage,
        events: ['course']
    },
    'courses/branding/:pageId': {
        access: 'design',
        path: '/courses/branding/:pageId',
        subMenu: 'courses/branding',
        idParams: 'pageId',
        component: BrandingPage,
        events: ['course']
    },
    'attendance': {
        access: 'attendance',
        path: '/attendance',
        title: 'attendance',
        menu: ['main', 'top'],
        component: AttendancePage,
        events: [ 'course']
    },
    'attendance/:pageId': {
        access: 'attendance',
        path: '/attendance/:pageId',
        subMenu: 'attendance/participants',
        idDesc:'attendance',
        idParams:'subPage',
        component: ParticipantsPage,
        events: [ 'course']
    },
    'tickets/groups': {
        access: 'manage event',
        path: '/tickets/groups',
        title: 'groups',
        subMenu: 'tickets',
        menu: ['top'],
        component: Groups,
        events: ['ticket','cluster']
    },
    'tickets/groups/:pageId': {
        access: 'manage event',
        path: '/tickets/groups/:pageId',
        subMenu: 'tickets/groups',
        idDesc:'groups',
        idParams:'pageId',
        menu: ['subTop'],
        component: GroupPage,
        events: ['ticket','cluster']
    },
    'tickets/reserved': {
        access: 'manage event',
        path: '/tickets/reserved',
        title: 'reserved',
        subMenu: 'tickets',
        component: 'MyIframe',
        events: ['table', 'area'],
        permission: 'reserved'
    },
    'tickets/coupons': {
        access: 'manage event',
        path: '/tickets/coupons',
        title: 'coupons',
        subMenu: 'tickets',
        component: 'MyIframe',
        events: ['ticket', 'cluster', 'area', 'timetable', 'givechak', 'listing', 'product', 'table']
    },
    'tickets/branding': {
        access: 'design',
        path: '/tickets/branding/email',
        title: 'branding',
        subMenu: 'tickets',
        component: BrandingPage,
        events: ['ticket', 'cluster', 'area', 'timetable', 'givechak', 'listing', 'product', 'table']
    },
    'tickets/branding/:pageId': {
        access: 'design',
        path: '/tickets/branding/:pageId',
        subMenu: 'tickets/branding',
        idParams: 'pageId',
        component: BrandingPage,
        events: ['ticket', 'cluster', 'area', 'timetable', 'givechak', 'listing', 'product', 'table']
    },
    'tickets': {
        access: 'manage event',
        path: '/tickets',
        title: 'tickets',
        subtitleItemTitle: 'tickets',
        menu: ['main', 'top'],
        component: Tickets,
        events: ['ticket', 'area', 'timetable', 'givechak', 'listing', 'product', 'table']
    },
    'team': {
        access: 'manage event',
        path: '/team',
        title: 'ofTeam',
        menu: ['main'],
        component: Team,
        events: ['ticket', 'area', 'timetable', 'givechak', 'listing', 'product', 'table', 'frontpage', 'course','hub']
    },
    'teamCluster': {
        access: 'manage event',
        path: '/team',
        title: 'clusterTeam',
        menu: ['main'],
        component: Team,
        events: ['cluster']
    },
    'designHub': {
        access: 'design',
        path: '/design',
        title: 'ofDesign',
        menu: ['main', 'top'],
        component:HubDesign,
        events: ['hub']
    },
    'design': {
        access: 'design',
        path: '/design',
        title: 'ofDesign',
        menu: ['main', 'top'],
        component: 'MyIframe',
        events: ['ticket', 'area', 'timetable', 'givechak', 'listing', 'product', 'table', 'course','frontpage']

    },
    'publishGivechak': {
        access: 'publish',
        path: '/publishGivechak',
        title: 'publish',
        subtitleItemTitle: 'leads',
        menu: ['main'],
        component: 'MyIframe',
        events: ['givechak']
    },
    'publishGivechak/pixels': {
        access: 'publish',
        path: '/publish/pixels',
        title: 'pixels',
        subMenu: 'publishGivechak',
        component: 'MyIframe',
        events: ['givechak']
    },
    'publishGivechak/embed': {
        access: 'publish',
        path: '/publish/embed',
        title: 'embed',
        subMenu: 'publishGivechak',
        component: 'MyIframe',
        events: ['givechak'],
        permission: 'publish embed'
    },
    'publishGivechak/language': {
        access: 'publish',
        path: '/publish/language',
        title: 'languages',
        subMenu: 'publishGivechak',
        component: 'MyIframe',
        events: ['givechak']

    },
    'publishGivechak/currencies': {
        access: 'master',
        path: '/publish/currencies',
        title: 'currencies',
        subMenu: 'publishGivechak',
        component: 'MyIframe',
        events: ['givechak']
    },

    'publish': {
        access: 'publish',
        path: '/publish',
        title: 'publish',
        subtitleItemTitle: 'leads',
        menu: ['main'],
        component: LeadPage,
        events: ['ticket', 'cluster', 'area', 'timetable', 'frontpage', 'listing', 'product', 'table', 'course', 'hub'],
    },
    'publish/pixels': {
        access: 'publish',
        path: '/publish/pixels',
        title: 'pixels',
        subMenu: 'publish',
        // component:'MyIframe',
        component:PixelsPage,
        events: ['ticket', 'cluster', 'area', 'timetable', 'frontpage', 'listing', 'product', 'table', 'course', 'hub'],
        permission:'publish pixel'
    },
    'publish/embed': {
        access: 'publish',
        path: '/publish/embed',
        title: 'embed',
        subMenu: 'publish',
        component: 'MyIframe',
        events: ['ticket', 'cluster', 'area', 'timetable', 'frontpage', 'listing', 'product', 'table', 'course'],
        permission: 'publish embed'
    },
    'publish/language': {
        access: 'publish',
        path: '/publish/language',
        title: 'languages',
        subMenu: 'publish',
        component: 'MyIframe',
        events: ['ticket', 'cluster', 'area', 'timetable', 'frontpage', 'listing', 'product', 'table', 'course']
    },
    'publish/currencies': {
        access: 'master',
        path: '/publish/currencies',
        title: 'currencies',
        subMenu: 'publish',
        component: 'MyIframe',
        events: ['ticket', 'cluster', 'area', 'timetable', 'frontpage', 'listing', 'product', 'table', 'course'],
        permission: 'currency'
    },
    'inbox': {
        access: 'manage event',
        path: 'inbox',
        title: 'inbox',
        component: 'MyIframe'

    },
    'mailing': {
        access: 'manage event',
        path: '/mailing',
        title: 'mailing',
        events: ['ticket', 'cluster', 'area', 'timetable', 'frontpage', 'listing', 'product', 'table', 'course', 'givechak'],
        component: Mailing
    },

    '404': {
        access: true,
        front: '404',
        title: 'pageNotFound'
    }

};
