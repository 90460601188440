import {MutableRefObject, useEffect, useState} from 'react';
import useTimeout from './useTimeout';

function useCopyItem() {
    const [copied, setCopied] = useState(false);
    const timer: MutableRefObject<any> = useTimeout();

    useEffect(() => {
        if(copied)
            timer.current=setTimeout(() => {
                setCopied(false);
            },3000); 
    }, [copied]);
 
    const copy = async (item) => {
        if(item){
            setCopied(true);
            const blob = await (await fetch(item)).blob();

            const clipboardItem = new ClipboardItem({'image/png': blob});
            navigator.clipboard.write([clipboardItem]).then(
                () => {
                    console.log('QR Code copied to clipboard!');
                },
                (err) => {
                    console.error('Failed to copy: ', err);
                }
            );
        }
    };

    return  {copy, isCopied:copied};
}

export default useCopyItem;
