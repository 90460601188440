import styled from 'styled-components';
import realtimeFonts from '../../pages/realtime/fonts';
import {BLACK, mobileMedia} from '../../globalVariables/GlobalVariables';
import {button} from '../button/buttonIcon/buttonIcon.css';

export const container= styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    justify-content: space-between;
    height: 100%;
    padding-top: 0;
    ${mobileMedia} {
        gap: 20px;
    }
`;
export const title = styled.div`
    ${realtimeFonts.windowTitle}
    text-align: center;
    color: ${BLACK};
  `;
export const buttons = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
       ${button}:last-child {
        &::before {
            background-size: 11px;
        }
    
}
`;