import {area, ticket} from '../../../assets/symbols/symbols/Symbols.css';

export type TType =  'ticket' | 'area'| 'scheduled';
export type TEventTypes={ [key in TType] : {text: string, details:string[], icon:string}}

export const eventTypes = {
    ticket: {
        text: 'ticketEvent',
        details: ['date', 'timeOpen', 'timeStart', 'category', 'location', 'timer'],
        icon: ticket,
        ticketsTitle: {
            soldTitle: 'soldTitleTicket',
            leftTitle: 'leftTitle',
            amountTitle: 'amountTitleTicket',
            averagePricePerTicket: 'averagePricePerTicket'
        }
    },
    area: {
        text: 'areaEvent',
        details: ['date', 'timeOpen', 'timeStart', 'category', 'location', 'timer'],
        icon: area,
        ticketsTitle: {
            soldTitle: 'soldTitleArea',
            leftTitle: 'leftTitle',
            amountTitle: 'amountTitleArea',
            purchaseProcess: 'purchaseProcess',
            free: 'freeSeats',
            close: 'closeSeats',
            saved: 'savedSeats',
            averagePricePerTicket: 'averagePricePerTicket'
        }
    },
    givechak: {
        text: 'givechakEvent',
        details: ['category'],
        icon: ticket,
        ticketsTitle: {
            soldTitle: 'soldTitleTicket',
            leftTitle: 'leftTitle',
            amountTitle: 'amountTitleTicket',
            averagePricePerTicket: 'averagePricePerTicket'
        }
    },
    scheduled: {
        text: 'scheduled',
        details: ['date', 'timeOpen', 'timeStart', 'category', 'location', 'timer'],
        icon: area,
        ticketsTitle: {
            soldTitle: 'soldTitleTicket',
            leftTitle: 'leftTitle',
            amountTitle: 'amountTitleTicket',
            purchaseProcess: 'purchaseProcess',
            averagePricePerTicket: 'averagePricePerTicket'
        }
    },
    default: {
        ticketsTitle:{
            soldTitle: 'soldTitleTicket',
            leftTitle: 'leftTitle',
            amountTitle: 'amountTitleTicket',
            averagePricePerTicket: 'averagePricePerTicket'
        }
    }
};