import WindowTemplate from '../../WindowTemplate/windowTemplate';

const grid = `"sectionTitle1 sectionTitle1 sectionTitle2 sectionTitle3"
                        "amountBold1 amountBold1 amountBold2 amountBold3"
                        "statsNumber1 span1 span1 progressWrapper"
                        "statsNumber2  span2 span2 progressWrapper"
                        "statsNumber3  span3 span3 progressWrapper"
                        "statsNumber4  span4 span4 progressWrapper"
                        "statsNumber5  span5 span5 progressWrapper"`;
const gridMoreStyle={      
    'grid-auto-rows':' 13% 32% 7% 7% 7% 7% 7%',             
    'grid-auto-columns': '1fr 1fr 2fr 2fr'
};
const settings = [
    {
        gridArea: 'sectionTitle1',
        width: '100%',
        height: '29px'
    },
    {
        gridArea: 'amountBold1',
        width: '100%',
        height: '65px'
    },
    {
        gridArea: 'sectionTitle2',
        width: '100%',
        height: '29px'

    },
    {
        gridArea: 'amountBold2',
        width: '100%',
        height: '65px'
        
    },
    {
        gridArea: 'sectionTitle3',
        width: '100%',
        height: '29px'

    },
    {
        gridArea: 'amountBold3',
        width: '100%',
        height: '65px'
        
    },
    {
        gridArea: 'statsNumber1',
        width: '50px',
        height: '19px',
        moreStyle:{
            'place-self': 'flex-start'
        },
    },
    {
        gridArea: 'statsNumber2',
        width: '50px',
        height: '19px',
        moreStyle:{
            'place-self': 'flex-start'
        },
    },
    {
        gridArea: 'statsNumber3',
        width: '50px',
        height: '19px',
        moreStyle:{
            'place-self': 'flex-start'
        }
    },
    {
        gridArea: 'statsNumber4',
        width: '50px',
        height: '19px',
        moreStyle:{
            'place-self': 'flex-start'
        }
    },
    {
        gridArea: 'statsNumber5',
        width: '50px',
        height: '19px',
        moreStyle:{
            'place-self': 'flex-start'
        }
    },
    {
        gridArea: 'span1',
        width: '85%',
        height: '19px',
        moreStyle: {
            'place-self': 'self-start',
        }

    },
    {
        gridArea: 'span2',
        width: '85%',
        height: '19px',
        moreStyle: {
            'place-self': 'self-start',
        }

    },
    {
        gridArea: 'span3',
        width: '85%',
        height: '19px',
        moreStyle: {
            'place-self': 'self-start',
        }

    },
    {
        gridArea: 'span4',
        width: '85%',
        height: '19px',
        moreStyle: {
            'place-self': 'self-start',
        }
    },
    {
        gridArea: 'span5',
        width: '85%',
        height: '19px',
        moreStyle: {
            'place-self': 'self-start',
        }

    },
    {
        gridArea: 'progressWrapper',
        width: '90px',
        height: '90px',
        moreStyle:{
            'box-shadow':' 0 3px 6px #00000029',
            'border-radius': '50%'
        }
    }

];
const TransactionsTemplate = () => {
    
    return <WindowTemplate gridMoreStyle={gridMoreStyle} gridAreas={grid} settings={settings} />;

};

export default TransactionsTemplate;