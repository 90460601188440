import styled from 'styled-components';
import {upload, topImage} from '../../components/logoStep/LogoStep.css';

export const hubLogoStep = styled.div`
    ${upload}{
        width: 180px;
        height: 65px;
    }
`;
export const hubLogoTopStep = styled.div`
    ${topImage}{
        width: 180px;
        height: 65px;
    }
`;