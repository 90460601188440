import request from '../../library/request';
import setGlobalState from '../setGlobalState';

export const getPromotionMessages = async () => {
    const res = await request('promotion.getPromotionMessages', 'get', '/promotionMessages');
    if(res?.messages)
        setGlobalState('promotion.promotionMessages', res.messages);
};
export const updateMessage = async (pmid, update) => {
    const res = await request('promotion.updatePromotionMessages', 'put', `/promotionMessages/${pmid}`, {update});
    if(res?.messages)
        setGlobalState('promotion.promotionMessages', res.messages);
};