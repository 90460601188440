import {useEffect, useState} from 'react';
import Btn from '../../button/btn/Btn';
import * as css from './GenerateImageScreen.css';
import {useTranslation} from 'react-i18next';
import {createSalePageImage, getAiImages} from '../../../redux/event/eventActions';
import {useSelector} from 'react-redux';
import tableLoader from '../../../assets/animations/tableLoader.json';
import Animation from '../../animation/Animation';
import {bounceMessage} from '../../../layout/userMessages/UserMessage';
import Image from './image/Image';
import useEffectOnUpdate from '../../../hooks/useEffectOnUpdate';
import EditTextPopup from '../../../pages/realtime/components/editTextPopup/EditTextPopup';
import useShow from '../../../hooks/useShow';
import List from '../../list/List';
import AnotherOneHorizontal from '../../anotherOneHorizontal/AnotherOneHorizontal';

function GenerateImageScreen({image, setImage}) {
    const {t} = useTranslation('newEvent');
    const styles = ['digitalArt', 'realistic', 'oilPainting', 'PicassoStyle', 'threeD', 'humor'];
    const event = useSelector((s) => s.event);
    const xhr = event.createSalePageImage;
    const imageSets = event.aiImages ? JSON.parse(event.aiImages) : {};
    const selectedStyles = new Set(Object.keys(imageSets));
    const imagesAmount = Object.values(imageSets).flat();
    const [currentStyle, setCurrentStyle] = useState('');
    const currentImages = imageSets[currentStyle];
    const currentImagesLength  = currentImages?.length || 0;
    const textShow = useShow();
    const listShow = useShow();
    const xhrLoading = xhr[`${currentStyle}XHR`]==='LOADING';
    
    const checkLoadingXHR = () => Object.values(xhr).find(value=> value === 'LOADING');

    const handleStyleClick = async (style) => {
        if(!checkLoadingXHR()){
            setCurrentStyle(style);
            setImage(null);
        }
    };
    const getNewImage = (userPrompt) => {
        if(event.getAiImagesXHR && !checkLoadingXHR() && imagesAmount.length < 10){
            createSalePageImage({title: event?.title,location:event?.location,type:event?.type,category:event?.category, style:currentStyle, userPrompt}).then(
                ()=>{
                    if(imagesAmount.length >= 9)
                        bounceMessage(t('designFullImages') ,'attention');
                }
            );
        }
    };
    useEffect(() => {
        if(!event.getAiImagesXHR)
            getAiImages().then(()=>{handleStyleClick('digitalArt');});
        else
            handleStyleClick('digitalArt');
    }, []);

    useEffectOnUpdate(()=>{
        if(event.getAiImagesXHR && !imageSets[currentStyle]){
            getNewImage();
        }
    },[currentStyle]);

    const list = [{text:t('createImage'), onClick: () => getNewImage()},{text:t('createPrompt'), onClick:textShow.open}];

    return (
        <css.container data-cy='generateImage'>
            <css.title>{t('createImageTitle')}</css.title>
            <css.description>{t('createImageDescription')}</css.description>
            <css.styles>
                {styles.map((item, index) => (
                    <Btn
                        key={index}
                        onClick={() => {
                            handleStyleClick(item);
                        }}
                        disabled={(!selectedStyles.has(item) && imagesAmount.length >= 10 ) || (checkLoadingXHR())}
                        className={`btn ${item === currentStyle ? 'active' : ''}`}
                    >
                        {t(item)}
                    </Btn>
                ))}
            </css.styles>
            <css.images>
                {currentImages?.map((item, index) => (
                    <Image
                        key={index}
                        item={item}
                        image={image} 
                        setImage={setImage}
                        data-cy={`image-${index}`}
                    />
                ))}
                {xhrLoading && <Image>
                    <Animation animation={tableLoader} />
                </Image>}
                {Array.from({length: (xhrLoading ? 3 : 4)  - currentImagesLength})
                    .map((_, index)=>(
                        <AnotherOneHorizontal key={index} onClick={listShow.open} disabled={(index != 0 || xhrLoading) ? true : imagesAmount.length >= 10}/>
                    ))
                }
            </css.images>
            {!!listShow.isOpen && <List list={list} showClass={listShow.showClass} close={listShow.close} width={'max-content'}/>}
            {!!textShow.isOpen && <EditTextPopup show={textShow} onSave={(text)=>{getNewImage(text); textShow.close();}}>
                <css.titleText>{t('createImage')}</css.titleText> 
                <css.text>{t('createPromptExplain')}</css.text>   
            </EditTextPopup>}
        </css.container>
    );
}

export default GenerateImageScreen;
