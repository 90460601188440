import React, {useRef} from 'react';
import {QRCodeCanvas} from 'qrcode.react';
import * as css from './QRCode.css';
import {useTranslation} from 'react-i18next';
import ButtonIcon from '../button/buttonIcon/buttonIcon';
import {LIGHT_BLUE3} from '../../globalVariables/GlobalVariables';
import useCopyItem from '../../hooks/useCopyItem';
import {downloadImage} from '../fileUpload/FileUpload';

function QRCode({url}){
    const qrRef = useRef();
    const {t} = useTranslation('layout');
    const  {copy, isCopied} = useCopyItem();
    const getCodeImage = () => {
        const ref = qrRef.current as any;
        if(ref){
            const canvas = ref.querySelector('canvas');
            const image = canvas.toDataURL('image/png');
            return image;
        }
    };
    const download = () => {
        const image = getCodeImage();
        if(image){
            downloadImage(image, 'qrcode.png');
        }
    };
    const copyImage = () => {
        const image = getCodeImage();
        if(image){
            copy(image);
        }
    };

    return (
        <css.container>
            <css.title>{t('QRCode')}</css.title>
            <div ref={qrRef}>
                <QRCodeCanvas size={200} value={url} />,
            </div>
            <css.buttons>
                <ButtonIcon iconName={'copyLink'} text={t(isCopied?'copied':'copying')} onClick={copyImage} />
                <ButtonIcon BGColor={LIGHT_BLUE3} iconName={'downloadArrow'} text={t('download')} onClick={download} />
            </css.buttons>
        </css.container>
    );
}

export default QRCode;
