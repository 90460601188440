import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import * as css from './EventMenu.css';
import EventMenuMenu from './eventMenuMenu/EventMenuMenu';
import {ts2date, ts2time} from '../../library/dateFunctions_old_to_be_deprecated';
import {useTranslation} from 'react-i18next';
import {ThemeProvider} from 'styled-components';
import EventMenuStats from '../eventMenu/eventMenuStats/EventMenuStats';
import FormButton from './formButtton/FormButtton';
import SettingsPopup from '../../pages/eventSettings/settingsPopup/SettingsPopup';
import PopUpFrame from '../../component/popUpFrame/PopUpFrame';
import useShow from '../../hooks/useShow';
import {Else, If, Then} from 'react-if';
import useTablet from '../../hooks/useTablet';
import {Link} from 'react-router-dom';
import EventRolePermission from '../../pages/components/rolePermission/EventRolePermission';
import {LIVE_DOMAIN, TICK_DOMAIN} from '../../config/config';
import hasSpecificTranslate from '../../library/hasSpecificTranslate';
import HubSettingsPopup from '../../pages/eventSettings/hubSettingsPopup/HubSettingsPopup';
import {useLocation, useParams} from 'react-router-dom';
import useEffectOnUpdate from '../../hooks/useEffectOnUpdate';
import useMobile from '../../hooks/useMobile';
import {isMaster} from '../../functions/permission';

function EventMenu(props) {
    const {t} = useTranslation('layout');
    const [miniDisplay, setMiniDisplay] = useState(0);
    const {page} = useParams();
    const oldSystem = useSelector(s => s.realtime.user.oldSystem);
    const {eid,timeStart,title,type,fbImg} = useSelector(s => s.event);
    const isRealtime = page === 'realtime' && !oldSystem && (['ticket', 'area'].includes(type) || (['givechak'].includes(type) && isMaster()));
    const location = useLocation();
    const isMobile = useMobile();

    const isTablet = useTablet();
    const eventTypsArr = {ticket: t('ticketEvent'), area: t('areaEvent'), timetable: t('timetableEvent'), givechak: t('givechak'), table: t('tableEvent'), frontpage: t('frontpage'), listing: t('listing'),cluster:t('cluster'),course:t('course')};
    let Langs = [];
    Langs['en'] = t('englishUs');
    Langs['he'] = t('hebrew');

    useEffect(() => {
        if(isRealtime && !isTablet ||  page === 'design'){
            setMiniDisplay(1);
        }
        else { 
            setMiniDisplay(0);
        }
    }, [page, isTablet]);

    useEffect(() => {
        if(props.updateMiniMenu)
            props.updateMiniMenu(miniDisplay);
    }, [miniDisplay]);

    useEffectOnUpdate(() => {    
        if(props.closeMenu && isMobile)   
            props.closeMenu();
    }, [location]);

    const ref = useRef(null);
    const {open, close, isOpen, showClass} = useShow( 500);

    let eventTypes = {
        hub:{Comp:HubSettingsPopup,domain:LIVE_DOMAIN},
        default:{Comp:SettingsPopup,domain:TICK_DOMAIN}
    };
        
    const {Comp,domain}= eventTypes[type]||eventTypes['default'];

    return (
        <ThemeProvider theme={{mini: miniDisplay, realtime: isRealtime}}>
            {eid && 
                <css.EventMenu>
                    <css.EventMenuTopBack img={fbImg}>
                        <css.EventMenuTop>
                            <css.EventMenuHead>
                                {!isTablet && <css.EMHeadMini onClick={() => {
                                    setMiniDisplay(!miniDisplay);
                                }} />}
                                <css.EMHeadType>{eventTypsArr[type]}</css.EMHeadType>
                            </css.EventMenuHead>
                            {(!isRealtime || !miniDisplay) &&  <css.EMTitleFrame>
                                <css.EventMenuTitle data-cy={'sideMenuTitle'}>{title}</css.EventMenuTitle>
                                <css.EMTitleLeft>
                                    <css.EventMenuDate data-cy={'sideMenuDate'} >
                                        <If condition={timeStart}>
                                            <Then>
                                                {ts2date(timeStart)}{miniDisplay ? <br /> :
                                                    <span>*</span>}{ts2time(timeStart)}
                                            </Then>
                                            <Else>
                                                {t('continuousEvent')}
                                            </Else>
                                        </If>
                                    </css.EventMenuDate>
                                
                                </css.EMTitleLeft>
                            </css.EMTitleFrame>}
                        </css.EventMenuTop>
                    </css.EventMenuTopBack>
                    <css.bottomMenu>
                        {(!isRealtime || !miniDisplay) && <FormButton isMiniDisplay={miniDisplay} domain={domain}/>}
                        {(!isRealtime || !miniDisplay) && <css.wrapStats>
                            {type!=='hub'&&<><EventMenuStats mini={miniDisplay} />
                                <Link to={`/e/${eid}/realtime`}>
                                    <css.control>
                                        {t('control')}
                                    </css.control>
                                </Link> </>}
                        </css.wrapStats>}
                        <EventMenuMenu miniDisplay={miniDisplay} closeMenu={props.closeMenu} />
                        <EventRolePermission rolePermission='manage event'>
                            <css.EventMenuBot>
                                {/*<css.EventMenuLang>*/}
                                {/*    <css.menuBotImg className={'lang'} />*/}
                                {/*    <css.menuBotText>{Langs[event.lang]}</css.menuBotText>*/}
                                {/*</css.EventMenuLang>*/}
                                <css.EventMenuDef data-cy="eventSettingBtn" onClick={open}>
                                    {/* <css.EventMenuDef onClick={() => iframeClick('settings')}> */}
                                    <css.menuBotImg className={'def'} />
                                    <css.menuBotText>{hasSpecificTranslate(`${type}Settings`,t)||t('eventSettings')}</css.menuBotText>
                                </css.EventMenuDef>
                            </css.EventMenuBot>
                        </EventRolePermission>
                    </css.bottomMenu>
                </css.EventMenu>
            }
            {isOpen &&
                <PopUpFrame showClass={showClass}>
                    <div ref={ref}>
                        <Comp showClass={showClass} close={close} />
                    </div>
                </PopUpFrame>
            }
        </ThemeProvider>
    );
}

export default EventMenu;
