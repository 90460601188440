import styled from 'styled-components';
import {text} from '../../../../../../../component/modeBtn/ModeBtn.css';
import {BGImageCenter, ellipsis} from '../../../../../../../globalVariables/GlobalVariables';
import {pencil} from '../../../../../../../assets/symbols/symbols/Symbols.css';

export const item = styled.div`
    width: 100px;
    overflow: hidden;
    ${ellipsis}
`;

export const boldItem = styled(item)`
    font-weight: 700;
    text-align: center;

`;
export const edit = styled(item)`
    text-align: end;
    opacity: 0.5;
    &::before{
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        position: absolute;
        ${BGImageCenter(pencil,'10px')};
    }
`;

export const toggle = styled.div`
    width: 80px;
`;
