const zIndex={
    under:-1,
    base:0,
    baseRaised:1,
    baseHigh:2,
    baseHighest:10,
    dropDown:100,
    underModal:900,
    basicModal:1000,
    upperModal:2000,
    highestModal:3000,
};
export default zIndex;