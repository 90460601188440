import {useState, useEffect} from 'react';
// import TransactionsQueue from '../../../component/TransactionsQueue/TransactionsQueue';
import Cookies from 'js-cookie';

function Queue() {
    const [hasCookie, setHasCookie] = useState(false);

    useEffect(() => {
        const tickchakQueue = Cookies.get('tickchakQueue');
        setHasCookie(!!tickchakQueue);
    }, []);

    if(!hasCookie) {
        return <></>;
    }
    
    return (
        <>
            {/* <TransactionsQueue /> */}
        </>
    );
}

export default Queue;
