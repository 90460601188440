import {useTranslation} from 'react-i18next';
import * as css from './MarketingRow.css';
import ModeBtn from '../../../../../../../component/modeBtn/ModeBtn';
import {updateMessage} from '../../../../../../../redux/promotion/promotionActions';

function MarketingRow({message}) {
    const {t} = useTranslation('promotionMessage');
    const activeMessage = async () => {
        await updateMessage(message.pmid, {active: !message.active});
    };
    return (
        <>
            <css.item>
                {t(message.type)}
            </css.item>
            <css.edit>
                {t('edit')}
            </css.edit>
            <css.boldItem>
                {message.events || 0}
            </css.boldItem>
            <css.boldItem>
                {message.viewAmount || 0}
            </css.boldItem>
            <css.boldItem>
                {message.sendClickAmount || 0}
            </css.boldItem>
            <css.toggle>
                <ModeBtn className="" click={activeMessage} status={message.active} requestStatus={undefined} dataTitle={''} text={''} dataCyName={'activeMessage'}/>
            </css.toggle>
        </>
    );
}
export default MarketingRow;
