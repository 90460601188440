import * as css from './HubLogoStep.css';
import LogoStep from '../../components/logoStep/LogoStep';

function HubLogoStep() {

    return (
        <css.hubLogoStep>
            <LogoStep size={{width: 180, height: 65}}/>
        </css.hubLogoStep>
    );
}

export default HubLogoStep;
