import styled from 'styled-components';
import zIndex from '../../../globalVariables/zIndex';
import {boxOffice} from '../../../layout/boxoffice/Boxoffice.css';
import {
    BLACK,
    WHITE,
    mobileMedia,
    tabletPlusMedia,
    resolveImage,
    BGImageCenter
} from '../../../globalVariables/GlobalVariables';

import {wrapChild} from '../../../component/tooltip/Tooltip.css';
import {cashRegister, displayMode, displayModeWhite} from '../../../assets/symbols/symbols/Symbols.css';

import {popUpBody} from '../../../component/newPopup/mobilePopup/MobilePopup.css';

export const top = styled.div`
    height: 60px;
    display: flex;
    justify-content: flex-end;
    width: 80%;
    position: relative;
    inset-inline-start: 10%;
    align-items: center;
    gap: 12px;
    ${mobileMedia}{
        position: absolute;
        width: 50%;
        inset-block-end: -30px;
        inset-inline-start: 10px;
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        z-index: ${zIndex.baseHigh};
    }
    ${wrapChild}{
        ${boxOffice}{
            ${tabletPlusMedia}{
                width: 35px;
                height: 60px;
                background-color: ${p=>p.theme.isDarkMode? WHITE : BLACK};
                background-image: none;
                -webkit-mask-image: url("${resolveImage(cashRegister)}");
                mask-image: url("${resolveImage(cashRegister)}");
                -webkit-mask-position: center;
                -webkit-mask-repeat: no-repeat;
                -webkit-mask-size: 25px 25px ;
                box-shadow: none;
            }
        }
    }
    ${popUpBody} {
        overflow-y: hidden;
    }
 `;
export const backgroundDisplay = styled.div`
    height: 60px;
    width: 35px;
    cursor: pointer;
    ${p=>BGImageCenter(p.theme.isDarkMode ? displayModeWhite : displayMode, '25px 25px')};
    transition:all 400ms;
    &:hover{
        transform: scale(1.1);
    }
    ${mobileMedia}{
        position: absolute;
        inset-block-end: -25px;
        ${BGImageCenter(displayMode, '25px 25px')}
    }
 `;
