import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useLocation, withRouter} from 'react-router-dom';
import {TICK_DOMAIN_MY,REACT_APP_ENV} from '../../config/config';
import readCookie from '../../library/readCookie';
import setGlobalState from '../../redux/setGlobalState';
import useMobile from '../../hooks/useMobile';
import {Else, If, Then} from 'react-if';
import BlockPage from './blockPage/BlockPage';
import TickchakLoaderXHR from '../../component/tickchakLoader/TickchakLoaderXHR';
import zIndex from '../../globalVariables/zIndex';
import * as css from './MyIframe.css';

export const reloadIframe=()=>{
    setGlobalState('ui.layout.isReloadIframe',true);
};

const getSelectedQueryParameters = (selectedParams) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    let result = [];
    
    for(const param of selectedParams) {
        if(queryParams.has(param)) {
            result.push(`${param}=${queryParams.get(param)}`);
        }
    }
    
    return result.length > 0 ? '&' + result.join('&') : '';
};

function MyIframe({location,iframeRef,display}) {
    const tickchakToken = readCookie('tickchak_token');
    const isReloadIframe=useSelector(s=>s.ui.layout.isReloadIframe);
    const [iframeId,setIframeId]=useState(1);
    const [isLoading,setIsLoading]=useState(true);
    const timer=useRef(null);
    const page = location.pathname.split('/').pop();
    const [blockPage, setBlockPage]=useState(true);
    const isMobile = useMobile();
    const reloadXHR=()=>{
        setIsLoading(false);
        clearTimeout(timer.current);
        timer.current=setTimeout(() => {
            setIsLoading(true);
        },300);
    };

    useEffect(reloadXHR,[location.pathname]);

    useEffect(()=>{
        if(isReloadIframe){
            reloadXHR();
            setIframeId(prev=>prev+1);
            setGlobalState('ui.layout.isReloadIframe',false); 
        }
    },[isReloadIframe]);

    useEffect(()=>{
        if(page!=='realtime')
            setBlockPage(true);
    },[location?.pathname ]);

    let iframeSrc = `${TICK_DOMAIN_MY}${display ? location.pathname : '/p/0'}?tickchakNewApp`;
    iframeSrc = iframeSrc.replace('publishGivechak', 'publish_givechak');

    if(REACT_APP_ENV === 'local'){
        iframeSrc += `&tickchak_token=${tickchakToken}`;
    }
    iframeSrc += getSelectedQueryParameters(['oid', 'lead', 'rpid', 'area', 'id']);

    return (
        <>
            {display&&<If condition={isMobile && blockPage && page === 'design'}>
                <Then>
                    <BlockPage setBlockPage={setBlockPage}/>
                </Then>
                <Else>
                    <TickchakLoaderXHR xhr={isLoading ? 'LOADING' : null}
                        cssStyle={`position: absolute; padding: 40px; z-index:${zIndex.baseRaised};`}/>
                </Else>
            </If>}
            <css.iframe
                className={!display && 'hide'}
                id={'iframe'}
                key={`mainIframe${iframeId}`}
                ref={iframeRef}
                src={iframeSrc}
                onLoad={()=>setIsLoading(false)}
            />
        </>
    );

}
export default withRouter(MyIframe);
