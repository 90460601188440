import * as css from './RealtimeHeader.css';
import {useSelector} from 'react-redux';
import {updateRealtimeDesign} from '../../../redux/realtime/realtimeActions';
import Tooltip from '../../../component/tooltip/Tooltip';
import MasterPermission from '../../components/rolePermission/MasterPermission';
import SystemMessageDisplayPop from '../components/windows/systemMessage/systemMessageDisplayPop';
import SystemMessageMenu from '../components/systemMessage/systemMessageMenu/systemMessageMenu';
import MarketingPromotionsMenu from '../components/marketingPromotions/marketingPromotionsMenu/marketingPromotionsMenu';
import {useTranslation} from 'react-i18next';

function RealtimeHeader ({isEvent=false}:{isEvent?:boolean}){

    const {t} = useTranslation('realtime');
    const realtimeDesign = useSelector((s:any) => s.realtime.user.realtimeDesign);

    const setDisplay = () => {
        const darkDisplay = realtimeDesign ? !realtimeDesign.darkDisplay : true;
        updateRealtimeDesign('darkDisplay', darkDisplay).then();
    };
    return (
        <css.top>
            <MasterPermission>
                <SystemMessageMenu/>
            </MasterPermission>
            <SystemMessageDisplayPop/>
            <MarketingPromotionsMenu/>
            {isEvent&&
                <Tooltip text={t('changedDisplay')} dataTitleIcon={undefined}>
                    <css.backgroundDisplay onClick={setDisplay} data-cy="changedDisplay"/>
                </Tooltip>
            }
            {/* temp windows control:
                    {windowsList?.map(win=><ModeBtn click={()=>showHideWin(win, !getShowWindow(win))} key={win} status={getShowWindow(win)} dataTitle={undefined} className={undefined} text={win} dataCyName={undefined} requestStatus={undefined} />)} */}
        </css.top>

    );
}

export default RealtimeHeader;
